<template>
  <loading-dialog :loading="$apollo.loading">
    <v-container class="px-0" fluid>
      <v-row justify="end">
        <v-col cols="auto">
          <mf-button label="Adicionar Bandeira" @click="handleOpenModalCreateAndUpdate" />
        </v-col>
      </v-row>

      <v-card flat class="pt-4 px-4">
        <span class="card-title">
          <v-icon class="mr-1 mb-1">mdi-flag</v-icon>
          Bandeiras
        </span>

        <v-container fluid>
          <v-row>
            <v-col v-for="flag in flags" :key="flag.id" cols="12" class="px-0 py-0 mb-2">
              <v-card class="px-4">
                <v-row justify="space-between">
                  <v-card-title class="card-title">{{ flag.name }}</v-card-title>

                  <v-card-actions>
                    <v-btn density="comfortable" icon="$close" variant="plain" @click="handleOpenModalCreateAndUpdate(true, flag)">
                      <v-icon>mdi-pen</v-icon>
                    </v-btn>

                    <v-btn density="comfortable" icon="$close" variant="plain" @click="handleOpenModalDelete(flag.id)">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card>

      <v-dialog v-model="isOpenModalDeleteRetailerFlag" max-width="400">
        <v-card class="px-1 py-2">
          <v-card-item>
            <v-card-title>Deletar a Bandeira</v-card-title>

            <v-card-subtitle class="justify-start">Realmente deseja deletar essa bandeira?</v-card-subtitle>
          </v-card-item>

          <v-card-actions class="justify-end mr-2 mb-2 mt-2">
            <v-btn color="red darken-1" width="30%" outlined @click="isOpenModalDeleteRetailerFlag = false">CANCELAR</v-btn>

            <v-btn color="blue darken-1" width="30%" @click="handleDeleteRetailerFlag">DELETAR</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="isOpenModalCreateAndUpdateRetailerFlag" max-width="1000">
        <loading-dialog :loading="$apollo.loading">
          <v-card class="px-1 py-2">
            <v-card-item>
              <v-card-title>{{ titleModalCreateAndUpdate }}</v-card-title>
              <v-row class="justify-around ml-4">
                <v-col cols="6" class="mr-3">
                  <mf-text-input v-model="input.name" outlined label="NOME" />
                </v-col>

                <v-col cols="2" class="mr-3">
                  <tooltip-switch
                    class-props="mt-1"
                    :editable="true"
                    :right="true"
                    :value.sync="input.defaultActiveFlag"
                    label="Bandeira Padrão"
                    :tip="appFlagTip"
                    @update:value="value => (input.defaultActiveFlag = value)"
                  />
                </v-col>
              </v-row>

              <v-col class="py-0">
                <v-card-title><v-icon class="mb-1 mr-2">mdi-cog</v-icon>IMAGENS</v-card-title>

                <v-col class="ml-1">
                  <v-row>
                    <v-col cols="3">
                      <label>Logo da Bandeira</label>
                      <v-btn class="mt-4" color="blue darken-1" outlined @click="handleOpenInputSelectFile(true)">IMPORTAR LOGO</v-btn>
                      <span>Tamanho: 340 X 150</span>
                      <input id="input-select-file" ref="input-select-file" type="file" accept="image/png" @change="changeFileInput" />
                    </v-col>

                    <img v-if="input.logoSelector" :src="input.logoSelector" class="pa-0 ma-2" />
                  </v-row>

                  <v-row>
                    <v-col cols="3">
                      <label>MASTER ICON NAVBAR</label>
                      <v-btn class="mt-4" color="blue darken-1" outlined @click="handleOpenInputSelectFile(false)">IMPORTAR ICONE</v-btn>
                      <span>Tamanho: 300 X 84</span>
                      <input
                        id="input-select-file-master-icon"
                        ref="input-select-file-master-icon"
                        type="file"
                        accept="image/png"
                        @change="changeFileInputMasterIcon"
                      />
                    </v-col>

                    <img v-if="input.assets.masterIconNavbar" :src="input.assets.masterIconNavbar" class="pa-0 ma-2" />
                  </v-row>
                </v-col>
              </v-col>

              <v-col class="py-0">
                <v-card-title><v-icon class="mb-1 mr-2">mdi-cog</v-icon>BACKGROUND ICON</v-card-title>

                <color-picker
                  class="ml-4 mr-1"
                  :rules="[notEmpty, maxLength7]"
                  :edit="true"
                  label="BACKGROUND ICON"
                  :color.sync="input.colors.iconBackgroundColor"
                />
              </v-col>

              <v-row class="px-4 ml-0">
                <v-col cols="4" class="py-0">
                  <v-card-title class="px-0"><v-icon class="mb-1 mr-2">mdi-cog</v-icon>BACKGROUND DEFAULT</v-card-title>

                  <color-picker
                    :rules="[notEmpty, maxLength7, input.colors.primary !== input.colors.primaryText || 'PRIMARY não pode ser igual a PRIMARY_TEXT']"
                    :edit="true"
                    label="PRIMARY"
                    :color.sync="input.colors.primary"
                    :contrast-text-color="input.colors.primaryText"
                  />

                  <color-picker
                    :rules="[notEmpty, maxLength7, input.colors.secondary !== input.colors.secondaryText || 'SECONDARY não pode ser igual a SECONDARY_TEXT']"
                    :edit="true"
                    label="SECONDARY"
                    :color.sync="input.colors.secondary"
                    :contrast-text-color="input.colors.secondaryText"
                  />
                </v-col>

                <v-col cols="4" class="py-0">
                  <v-card-title class="px-0"><v-icon class="mb-1 mr-2">mdi-cog</v-icon>BACKGROUND LIGHT</v-card-title>

                  <color-picker
                    :rules="[
                      notEmpty,
                      maxLength7,
                      input.colors.primaryLight !== input.colors.primaryTextLight || 'PRIMARY_LIGHT não pode ser igual a PRIMARY_TEXT_LIGHT'
                    ]"
                    :edit="true"
                    label="PRIMARY_LIGHT"
                    :color.sync="input.colors.primaryLight"
                    :contrast-text-color="input.colors.primaryTextLight"
                  />

                  <color-picker
                    :rules="[
                      notEmpty,
                      maxLength7,
                      input.colors.secondaryLight !== input.colors.secondaryTextLight || 'SECONDARY_LIGHT não pode ser igual a SECONDARY_TEXT_LIGHT'
                    ]"
                    :edit="true"
                    label="SECONDARY_LIGHT"
                    :color.sync="input.colors.secondaryLight"
                    :contrast-text-color="input.colors.secondaryTextLight"
                  />
                </v-col>

                <v-col cols="4" class="py-0">
                  <v-card-title class="px-0"><v-icon class="mb-1 mr-2">mdi-cog</v-icon>BACKGROUND DARK</v-card-title>

                  <color-picker
                    :rules="[
                      notEmpty,
                      maxLength7,
                      input.colors.primaryDark !== input.colors.primaryTextDark || 'PRIMARY_DARK não pode ser igual a PRIMARY_TEXT_DARK'
                    ]"
                    :edit="true"
                    label="PRIMARY_DARK"
                    :color.sync="input.colors.primaryDark"
                    :contrast-text-color="input.colors.primaryTextDark"
                  />

                  <color-picker
                    :rules="[
                      notEmpty,
                      maxLength7,
                      input.colors.secondaryDark !== input.colors.secondaryTextDark || 'SECONDARY_DARK não pode ser igual a SECONDARY_TEXT_DARK'
                    ]"
                    :edit="true"
                    label="SECONDARY_DARK"
                    :color.sync="input.colors.secondaryDark"
                    :contrast-text-color="input.colors.secondaryTextDark"
                  />
                </v-col>
              </v-row>

              <v-row class="px-4 ml-0">
                <v-col cols="4" class="py-0">
                  <v-card-title class="px-0"><v-icon class="mb-1 mr-2">mdi-cog</v-icon>TEXT DEFAULT</v-card-title>

                  <color-picker
                    :rules="[notEmpty, maxLength7, input.colors.primary !== input.colors.primaryText || 'PRIMARY não pode ser igual a PRIMARY_TEXT']"
                    :edit="true"
                    label="PRIMARY_TEXT"
                    :color.sync="input.colors.primaryText"
                  />

                  <color-picker
                    :rules="[notEmpty, maxLength7, input.colors.secondary !== input.colors.secondaryText || 'SECONDARY não pode ser igual a SECONDARY_TEXT']"
                    :edit="true"
                    label="SECONDARY_TEXT"
                    :color.sync="input.colors.secondaryText"
                  />
                </v-col>

                <v-col cols="4" class="py-0">
                  <v-card-title class="px-0"><v-icon class="mb-1 mr-2">mdi-cog</v-icon>TEXT LIGHT</v-card-title>

                  <color-picker
                    :rules="[
                      notEmpty,
                      maxLength7,
                      input.colors.primaryLight !== input.colors.primaryTextLight || 'PRIMARY_LIGHT não pode ser igual a PRIMARY_TEXT_LIGHT'
                    ]"
                    :edit="true"
                    label="PRIMARY_TEXT_LIGHT"
                    :color.sync="input.colors.primaryTextLight"
                  />

                  <color-picker
                    :rules="[
                      notEmpty,
                      maxLength7,
                      input.colors.secondaryLight !== input.colors.secondaryTextLight || 'SECONDARY_LIGHT não pode ser igual a SECONDARY_TEXT_LIGHT'
                    ]"
                    :edit="true"
                    label="SECONDARY_TEXT_LIGHT"
                    :color.sync="input.colors.secondaryTextLight"
                  />
                </v-col>

                <v-col cols="4" class="py-0">
                  <v-card-title class="px-0"><v-icon class="mb-1 mr-2">mdi-cog</v-icon>TEXT DARK</v-card-title>

                  <color-picker
                    :rules="[
                      notEmpty,
                      maxLength7,
                      input.colors.primaryDark !== input.colors.primaryTextDark || 'PRIMARY_DARK não pode ser igual a PRIMARY_TEXT_DARK'
                    ]"
                    :edit="true"
                    label="PRIMARY_TEXT_DARK"
                    :color.sync="input.colors.primaryTextDark"
                  />

                  <color-picker
                    :rules="[
                      notEmpty,
                      maxLength7,
                      input.colors.secondaryDark !== input.colors.secondaryTextDark || 'SECONDARY_DARK não pode ser igual a SECONDARY_TEXT_DARK'
                    ]"
                    :edit="true"
                    label="SECONDARY_TEXT_DARK"
                    :color.sync="input.colors.secondaryTextDark"
                  />
                </v-col>
              </v-row>

              <v-col class="py-0">
                <v-card-title><v-icon class="mb-1 mr-2">mdi-cog</v-icon>SPLASH</v-card-title>

                <color-picker class="ml-4 mr-1" :rules="[notEmpty, maxLength7]" :edit="true" label="SPLASH" :color.sync="input.colors.splash" />
              </v-col>

              <v-col v-if="input.colors.composition.general" cols="5" class="py-0">
                <v-row>
                  <v-card-title class="ml-4">
                    <v-icon class="mb-1 mr-2">mdi-cog</v-icon>
                    COMPOSIÇÃO GERAL
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" class="ml-2" v-on="on">
                          <v-icon small>info</v-icon>
                        </div>
                      </template>

                      <span>
                        Este campo reflete na identidade visual do site, mas não afeta os componentes listados abaixo(Composição por componente).
                      </span>
                    </v-tooltip>
                  </v-card-title>
                </v-row>

                <v-select
                  v-model="input.colors.composition.general"
                  :disabled="false"
                  label="Composição geral"
                  class="ml-4"
                  outlined
                  :items="composition_color_items"
                ></v-select>
              </v-col>

              <v-col v-if="input.colors.composition.components.length" class="py-0">
                <v-card-title class="ml-1"><v-icon class="mb-1 mr-2">mdi-cog</v-icon>COMPOSIÇÃO POR COMPONENTE</v-card-title>

                <v-row class="ml-2">
                  <v-col v-for="component in input.colors.composition.components" :key="component.name" cols="4">
                    <v-select v-model="component.value" :disabled="false" :label="component.name" outlined :items="composition_color_items"></v-select>
                  </v-col>
                </v-row>
              </v-col>
            </v-card-item>

            <v-card-actions class="justify-end mr-2 mb-2 mt-2">
              <v-btn class="mr-2" color="red darken-1" width="30%" outlined @click="handleCloseModalCreateAndUpdate">CANCELAR</v-btn>

              <v-btn color="blue darken-1" width="30%" @click="edit ? handleUpdateRetailerFlag() : handleCreateRetailerFlag()">{{
                textButtonModalCreateAndUpdate
              }}</v-btn>
            </v-card-actions>
          </v-card>
        </loading-dialog>
      </v-dialog>
    </v-container>
  </loading-dialog>
</template>

<script>
import { QUERY_GET_FLAGS_BY_ACCOUNTID } from '@/modules/accounts/graphql'
import {
  MUTATION_DELETE_RETAILER_FLAG,
  QUERY_RETAILER_FLAG_DEFAULT_VALUES,
  MUTATION_CREATE_RETAILER_FLAG,
  MUTATION_UPDATE_RETAILER_FLAG,
  MUTATION_SINGLE_UPLOAD_IMAGE_BASE64
} from '@/modules/accounts/graphql/edit/retailerFlags'
import { genHexLightDark } from '@/helpers/genHexLightDark'
import { readableColor } from 'polished'
import { debounceTime } from '@/helpers/debounceTime'

export default {
  components: {
    LoadingDialog: () => import('@/components/base/LoadingDialog'),
    ColorPicker: () => import('@/components/colors/ColorPicker.vue'),
    TooltipSwitch: () => import('@/components/base/TooltipSwitch')
  },
  data: () => ({
    isOpenModalDeleteRetailerFlag: false,
    isOpenModalCreateAndUpdateRetailerFlag: false,
    accountId: null,
    idFlagDelete: null,
    notEmpty: v => !!v || 'Campo obrigatório',
    maxLength7: v => (v || '').length <= 7 || 'Campo não pode ter mais que 7 caractéres',
    appFlagTip: 'Define Bandeira como padrão no aplicativo.',
    composition_color_items: ['PRIMARY', 'SECONDARY', 'MINIMAL'],
    titleModalCreateAndUpdate: '',
    textButtonModalCreateAndUpdate: '',
    flags: [],
    edit: false,
    input: {
      id: '',
      name: '',
      defaultActiveFlag: false,
      logoSelector: '',
      assets: {
        masterIconNavbar: ''
      },
      colors: {
        composition: {
          components: [{ name: '', value: '' }],
          general: ''
        },
        iconBackgroundColor: '',
        primary: '',
        primaryDark: '',
        primaryLight: '',
        primaryText: '',
        primaryTextDark: '',
        primaryTextLight: '',
        secondary: '',
        secondaryDark: '',
        secondaryLight: '',
        secondaryText: '',
        secondaryTextDark: '',
        secondaryTextLight: '',
        splash: ''
      }
    }
  }),
  apollo: {
    getFlagsByAccountID: {
      query: QUERY_GET_FLAGS_BY_ACCOUNTID,
      fetchPolicy: 'network-only',
      variables() {
        return { accountId: this.$route.params.id }
      },
      context() {
        return {
          uri: this.$microservicesUrls['accounts'],
          headers: {
            authorization: localStorage.getItem('session_id')
          }
        }
      },
      result({ data }) {
        this.flags = data.retailerFlags
      }
    }
  },
  watch: {
    'input.colors.primary': function(color, oldColor) {
      this.onAfterColorChange(color, oldColor, () => {
        const [baseLight, baseDark] = genHexLightDark(color)

        this.input.colors = {
          ...this.input.colors,
          primaryLight: baseLight,
          primaryDark: baseDark,
          primaryText: this.customReadableColor(color),
          primaryTextLight: this.customReadableColor(baseLight),
          primaryTextDark: this.customReadableColor(baseDark)
        }
      })
    },
    'input.colors.primaryLight': function(color, oldColor) {
      this.onAfterColorChange(color, oldColor, () => {
        this.input.colors.primaryTextLight = this.customReadableColor(color)
      })
    },
    'input.colors.primaryDark': function(color, oldColor) {
      this.onAfterColorChange(color, oldColor, () => {
        this.input.colors.primaryTextDark = this.customReadableColor(color)
      })
    },
    'input.colors.secondary': function(color, oldColor) {
      this.onAfterColorChange(color, oldColor, () => {
        const [baseLight, baseDark] = genHexLightDark(color)

        this.input.colors = {
          ...this.input.colors,
          secondaryLight: baseLight,
          secondaryDark: baseDark,
          secondaryText: this.customReadableColor(color),
          secondaryTextLight: this.customReadableColor(baseLight),
          secondaryTextDark: this.customReadableColor(baseDark)
        }
      })
    },
    'input.colors.secondaryLight': function(color, oldColor) {
      this.onAfterColorChange(color, oldColor, () => {
        this.input.colors.secondaryTextLight = this.customReadableColor(color)
      })
    },
    'input.colors.secondaryDark': function(color, oldColor) {
      this.onAfterColorChange(color, oldColor, () => {
        this.input.colors.secondaryTextDark = this.customReadableColor(color)
      })
    }
  },
  mounted() {
    this.accountId = this.$route.params.id
  },
  methods: {
    refresh() {
      this.$apollo.queries.getFlagsByAccountID.refresh()
    },
    customReadableColor(color) {
      return readableColor(color, '#000000', '#FFFFFF')
    },
    onAfterColorChange(color, oldColor, cb) {
      if (color && oldColor && color !== oldColor) {
        debounceTime(() => {
          return cb()
        }, 400)
      }
    },
    handleOpenModalDelete(idFlag) {
      this.idFlagDelete = idFlag
      this.isOpenModalDeleteRetailerFlag = true
    },
    handleCloseModalDelete() {
      this.idFlagDelete = null
      this.isOpenModalDeleteRetailerFlag = false
    },
    handleOpenModalCreateAndUpdate(edit, flag) {
      if (edit) {
        const newComponents = flag.app.colors.composition.components.map(item => ({ name: item.name, value: item.value }))

        this.input = {
          ...this.input,
          id: flag.id,
          name: flag.name,
          defaultActiveFlag: flag.default,
          logoSelector: flag.logoSelectorRetailerFlag,
          assets: {
            masterIconNavbar: flag.app.assets.masterIconNavbar
          },
          colors: {
            composition: {
              components: newComponents,
              general: flag.app.colors.composition.general
            },
            iconBackgroundColor: flag.app.colors.iconBackgroundColor,
            primary: flag.app.colors.primary,
            primaryDark: flag.app.colors.primaryDark,
            primaryLight: flag.app.colors.primaryLight,
            primaryText: flag.app.colors.primaryText,
            primaryTextDark: flag.app.colors.primaryTextDark,
            primaryTextLight: flag.app.colors.primaryTextLight,
            secondary: flag.app.colors.secondary,
            secondaryDark: flag.app.colors.secondaryDark,
            secondaryLight: flag.app.colors.secondaryLight,
            secondaryText: flag.app.colors.secondaryText,
            secondaryTextDark: flag.app.colors.secondaryTextDark,
            secondaryTextLight: flag.app.colors.secondaryTextLight,
            splash: flag.app.colors.splash
          }
        }
        this.edit = true
        this.titleModalCreateAndUpdate = `Editar Bandeira - ${flag.name}`
        this.textButtonModalCreateAndUpdate = `ATUALIZAR`
        this.isOpenModalCreateAndUpdateRetailerFlag = true
      } else {
        this.titleModalCreateAndUpdate = 'Criar Nova Bandeira'
        this.textButtonModalCreateAndUpdate = 'CRIAR'
        this.getRetailerFlagDefaultValues()
      }
    },
    handleCloseModalCreateAndUpdate() {
      this.edit = false
      this.input = {
        id: '',
        name: '',
        defaultActiveFlag: false,
        logoSelector: '',
        assets: {
          masterIconNavbar: ''
        },
        colors: {
          composition: {
            components: [{ name: '', value: '' }],
            general: ''
          },
          iconBackgroundColor: '',
          primary: '',
          primaryDark: '',
          primaryLight: '',
          primaryText: '',
          primaryTextDark: '',
          primaryTextLight: '',
          secondary: '',
          secondaryDark: '',
          secondaryLight: '',
          secondaryText: '',
          secondaryTextDark: '',
          secondaryTextLight: '',
          splash: ''
        }
      }
      this.isOpenModalCreateAndUpdateRetailerFlag = false
    },
    handleOpenInputSelectFile(logo) {
      if (logo) {
        this.$refs['input-select-file'].click()
      } else {
        this.$refs['input-select-file-master-icon'].click()
      }
    },
    validateImageDimensions(file, expectedWidth, expectedHeight, callback) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = event => {
        const img = new Image()
        img.src = event.target.result
        img.onload = () => {
          if (img.width !== expectedWidth || img.height !== expectedHeight) {
            callback(new Error('Invalid dimensions'))
          } else {
            callback(null, event.target.result)
          }
        }
      }
    },
    changeFileInput(e) {
      const file = e.target.files[0]
      if (file) {
        this.validateImageDimensions(file, 340, 150, (err, result) => {
          if (err) {
            this.$alert({
              alert_message: 'A LOGO deve conter tamanho de 340 X 150',
              alert_title: 'Erro!',
              alert_color: 'error',
              alert_icon: 'mdi-close-circle'
            })
          } else {
            this.singleUploadImageBase64(file.name, result, true)
          }
        })
      }
    },
    changeFileInputMasterIcon(e) {
      const file = e.target.files[0]
      if (file) {
        this.validateImageDimensions(file, 300, 84, (err, result) => {
          if (err) {
            this.$alert({
              alert_message: 'O MASTER ICON NAVBAR deve conter tamanho de 300 X 84',
              alert_title: 'Erro!',
              alert_color: 'error',
              alert_icon: 'mdi-close-circle'
            })
          } else {
            this.singleUploadImageBase64(file.name, result, false)
          }
        })
      }
    },
    async handleDeleteRetailerFlag() {
      const deleteRetailerFlagVariables = {
        accountId: this.accountId,
        id: this.idFlagDelete
      }
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_DELETE_RETAILER_FLAG,
          variables: deleteRetailerFlagVariables,
          context: {
            uri: this.$microservicesUrls['accounts'],
            headers: {
              authorization: localStorage.getItem('session_id')
            }
          }
        })

        this.$alert({
          alert_message: `'Bandeiras Deletada com Sucesso`,
          alert_title: 'Sucesso!',
          alert_color: 'success',
          alert_icon: 'mdi-check-circle'
        })
      } catch (err) {
        this.$alert({
          alert_message: 'Falha ao Deletar essa Bandeiras',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      } finally {
        this.refresh()
        this.handleCloseModalDelete()
      }
    },
    async getRetailerFlagDefaultValues() {
      const retailerFlagDefaultValuesVariables = {
        accountId: this.accountId
      }

      try {
        const { data } = await this.$apollo.query({
          query: QUERY_RETAILER_FLAG_DEFAULT_VALUES,
          variables: retailerFlagDefaultValuesVariables,
          context: {
            uri: this.$microservicesUrls['accounts'],
            headers: {
              authorization: localStorage.getItem('session_id')
            }
          }
        })

        const appColors = data.retailerFlagDefaultValues.app.colors

        const getColor = colorKey => {
          const color = appColors[colorKey]
          return color && color.length ? color : '#FFFFFF'
        }

        const newComponents = appColors.composition.components.map(item => ({ name: item.name, value: item.value }))

        this.input = {
          ...this.input,
          defaultActiveFlag: data.retailerFlagDefaultValues.default,
          assets: {
            masterIconNavbar: data.retailerFlagDefaultValues.app.assets.masterIconNavbar
          },
          colors: {
            composition: {
              components: newComponents,
              general: appColors.composition.general
            },
            iconBackgroundColor: getColor('iconBackgroundColor'),
            primary: getColor('primary'),
            primaryDark: getColor('primaryDark'),
            primaryLight: getColor('primaryLight'),
            primaryText: getColor('primaryText'),
            primaryTextDark: getColor('primaryTextDark'),
            primaryTextLight: getColor('primaryTextLight'),
            secondary: getColor('secondary'),
            secondaryDark: getColor('secondaryDark'),
            secondaryLight: getColor('secondaryLight'),
            secondaryText: getColor('secondaryText'),
            secondaryTextDark: getColor('secondaryTextDark'),
            secondaryTextLight: getColor('secondaryTextLight'),
            splash: getColor('splash')
          }
        }

        this.isOpenModalCreateAndUpdateRetailerFlag = true
      } catch (err) {
        this.$alert({
          alert_message: 'Falha ao Montar os Dados Default',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      }
    },
    async handleCreateRetailerFlag() {
      const createRetailerFlagVariables = {
        accountId: this.accountId,
        data: {
          app: {
            assets: {
              masterIconNavbar: this.input.assets.masterIconNavbar
            },
            colors: this.input.colors
          },
          default: this.input.defaultActiveFlag,
          logoSelector: this.input.logoSelector,
          name: this.input.name
        }
      }

      try {
        await this.$apollo.mutate({
          mutation: MUTATION_CREATE_RETAILER_FLAG,
          variables: createRetailerFlagVariables,
          context: {
            uri: this.$microservicesUrls['accounts'],
            headers: {
              authorization: localStorage.getItem('session_id')
            }
          }
        })

        this.refresh()
        this.handleCloseModalCreateAndUpdate()
        this.$alert({
          alert_message: `Bandeira Criada com Sucesso`,
          alert_title: 'Sucesso!',
          alert_color: 'success',
          alert_icon: 'mdi-check-circle'
        })
      } catch (err) {
        this.$alert({
          alert_message: 'Falha ao Criar uma Nova Bandeira',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      }
    },
    async handleUpdateRetailerFlag() {
      const updateRetailerFlagVariables = {
        accountId: this.accountId,
        data: {
          app: {
            assets: {
              masterIconNavbar: this.input.assets.masterIconNavbar
            },
            colors: this.input.colors
          },
          default: this.input.defaultActiveFlag,
          id: this.input.id,
          logoSelector: this.input.logoSelector,
          name: this.input.name
        }
      }

      try {
        await this.$apollo.mutate({
          mutation: MUTATION_UPDATE_RETAILER_FLAG,
          variables: updateRetailerFlagVariables,
          context: {
            uri: this.$microservicesUrls['accounts'],
            headers: {
              authorization: localStorage.getItem('session_id')
            }
          }
        })

        this.refresh()
        this.handleCloseModalCreateAndUpdate()
        this.$alert({
          alert_message: `Bandeira Atualizada com sucesso`,
          alert_title: 'Sucesso!',
          alert_color: 'success',
          alert_icon: 'mdi-check-circle'
        })
      } catch (err) {
        this.$alert({
          alert_message: 'Falha ao Atualizar essa Bandeira',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      }
    },
    async singleUploadImageBase64(filename, base64, logo) {
      const singleUploadImageBase64Variables = {
        filename,
        base64
      }

      try {
        const { data } = await this.$apollo.mutate({
          mutation: MUTATION_SINGLE_UPLOAD_IMAGE_BASE64,
          variables: singleUploadImageBase64Variables,
          context: {
            uri: this.$microservicesUrls['hulk'],
            headers: {
              authorization: localStorage.getItem('session_id')
            }
          }
        })

        if (logo) {
          this.input.logoSelector = data.singleUploadImageBase64.url
        } else {
          this.input.assets.masterIconNavbar = data.singleUploadImageBase64.url
        }
      } catch (err) {
        this.$alert({
          alert_message: 'Error ao Selecionar a Imagem',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      }
    }
  }
}
</script>

<style lang="scss">
.card-title {
  font-size: large;
}

#input-select-file,
#input-select-file-master-icon {
  display: none;
}
</style>
